
import { defineComponent } from "vue";
import _ from "lodash";
import helpers from "@/helpers/global";
export default defineComponent({
  name: "LogicTestScores",
  props: {
    sections: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
    logicTestSection: {
      type: Object,
      required: true,
    },
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questions: {} as any,
    };
  },
  created() {
    this.questions = this.sections[`section_${this.order}`];
  },
  methods: {
    getUserQuestion(qid: any) {
      const baseAnswerSection = `section${this.order}`;
      const question = this.logicTestSection.scenarios[
        baseAnswerSection
      ].questions.find((x: any) => x.id == qid);
      return question;
    },
    getQuestion(qid: any) {
      const path = `scenarios.section${this.order}.questions`;
      const question = _.get(this.logicTestSection, path).find(
        (x: any) => x.id == qid
      );
      return question;
    },
    get(qid: any, attr: string) {
      return _.get(this.getQuestion(qid), attr);
    },
    isCorrect(qid: any, answerData: any = null) {
      const answer = answerData[qid];
      const baseAnswer = _.get(this.getUserQuestion(qid), "answer");
      return answer == baseAnswer;
    },
    label(qid: any) {
      const text = (this as any).$t(
        "question.logicTest.question_button_label",
        this.getQuestion(qid)
      );
      return text;
    },
    getAnswerLabel(val: number) {
      const labels = {
        1: "A",
        2: "B",
        3: "C",
        4: "D",
      };
      return _.has(labels, val) ? _.get(labels, val) : "N/A";
    },
    getTooltipHtml(questionId: any) {
      const question = this.getQuestion(questionId);
      const $t = (this as any).$t;
      const isCorrect = this.isCorrect(questionId, this.questions);

      const path = `logic_test.section_${this.order}.${questionId}`;
      const userVal = _.get(this.data, path);
      const userOption = _.get(question, `options`).find(
        (x: any) => x.code == userVal
      );

      const correctVal = _.get(question, "answer");
      const correctOption = _.get(question, `options`).find(
        (x: any) => x.code == correctVal
      );

      const variables = {
        question_n: $t("question.logicTest.question_n", {
          n: _.get(question, "name"),
        }),
        title: helpers.objectLang(question, "title"),
        answer_n: $t("question.logicTest.answer_n", {
          n: this.getAnswerLabel(userVal),
          color: isCorrect ? "#22bc66" : "#ff523c",
        }),
        answer_label: helpers.objectLang(userOption, "label"),
        correct_answer_n: $t("question.logicTest.correct_answer_n", {
          n: this.getAnswerLabel(correctVal),
          color: "#000",
        }),
        correct_answer_label: helpers.objectLang(correctOption, "label"),
      };
      // console.log(variables, "variables");
      return `
        <div style="max-width: 508px; font-size: 12px">
          <h2 style="font-size: 12px">${variables.question_n}</h2>
          <p>${variables.title}</p>
          <p>
            ${variables.answer_n} ${variables.answer_label}
          </p>
          <p>
            ${variables.correct_answer_n} ${variables.correct_answer_label}
          </p>
        </div>
      `;
    },
  },
});
